import { w as withSharedProps, _ as __rest, a as __assign } from './withSharedProps-a1728349.js';
import React from 'react';
import { s as styleInject, a as secondaryColorDefaultProps } from './style-inject.es-fc9e633e.js';

var css_248z = "@keyframes spinners-react-infinity{0%{stroke-dasharray:1,347;stroke-dashoffset:75}25%,75%{stroke-dasharray:17,330}50%{stroke-dasharray:1,347}to{stroke-dasharray:1,347;stroke-dashoffset:423}}";
styleInject(css_248z);

var Component = function (_a) {
    var secondaryColor = _a.secondaryColor, speed = _a.speed, still = _a.still, thickness = _a.thickness, svgProps = __rest(_a, ["secondaryColor", "speed", "still", "thickness"]);
    var strokeWidth = 7 * (thickness / 100);
    var dashStyle = !still
        ? { animation: "spinners-react-infinity " + 140 / speed + "s linear infinite" }
        : {};
    return (React.createElement("svg", __assign({ fill: "none", viewBox: "0 0 131 55" }, svgProps),
        React.createElement("defs", null,
            React.createElement("path", { d: "M46.57 45.5138C36.346 55.4954 19.8919 55.4954 9.66794 45.5138C-0.55598 35.5321 -0.55598 19.4678 9.66794 9.48624C19.8919 -0.495412 36.346 -0.495412 46.57 9.48624L84.4303 45.5138C94.6543 55.4954 111.108 55.4954 121.332 45.5138C131.556 35.5321 131.556 19.4678 121.332 9.48624C111.108 -0.495412 94.6543 -0.495412 84.4303 9.48624L46.57 45.5138Z", id: "spinners-react-infinity-path" })),
        React.createElement("use", { stroke: secondaryColor, strokeWidth: strokeWidth, xlinkHref: "#spinners-react-infinity-path" }),
        React.createElement("use", { fill: "none", stroke: "currentColor", strokeDasharray: "1, 347", strokeDashoffset: "75", strokeLinecap: "round", strokeWidth: strokeWidth, style: dashStyle, xlinkHref: "#spinners-react-infinity-path" })));
};
Component.defaultProps = secondaryColorDefaultProps;
var SpinnerInfinity = withSharedProps(Component);

export { SpinnerInfinity };
